import { ng } from '@pretto/bricks/components/layout'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const ILLUSTRATION = '/v1637683150/zen/static-assets/illustrations/660x660/threePeopleChating.svg'
const TITLE = 'Ils parlent de nous !'

const Article = ({ author, date, picture, title, url }) => (
  <S.Article href={url}>
    <S.Picture path={picture} width={ng(15)} height={ng(9)} />
    <div>
      <S.ArticleTitle>{title}</S.ArticleTitle>
      <S.Details>
        {author} | {date}
      </S.Details>
    </div>
  </S.Article>
)

Article.propTypes = {
  author: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  picture: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

const BlocArticleLink = ({ articles, ...props }) => (
  <S.Grid>
    <S.BlocArticleLink {...props}>
      <S.Illustration width={ng(40)} height={ng(40)} path={ILLUSTRATION} />
      <S.ArticleBlock>
        <S.Title>{TITLE}</S.Title>
        {articles.map((article, index) => (
          <Article key={index} {...article} />
        ))}
      </S.ArticleBlock>
    </S.BlocArticleLink>
  </S.Grid>
)

BlocArticleLink.propTypes = {
  articles: PropTypes.array.isRequired,
}

export default memo(BlocArticleLink)
