import IllustrationComponent from '@pretto/bricks/components/images/Illustration'
import { breakpoints, g, ng } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import * as typo from '@pretto/bricks/core/typography'
import Image from '@pretto/bricks/website/shared/components/Image'
import styled, { css } from 'styled-components'

export const Grid = styled.div`
  ${grid()};
  margin: ${g(4)} 0;
`
export const BlocArticleLink = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: ${g(3)};
  ${column([2, 4])};
  justify-content: center;
  align-items: center;
  margin: 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    grid-template-columns: repeat(8, 1fr);
    ${column([2, 8])};
  }
  @media screen and (min-width: ${breakpoints.laptop}) {
    grid-template-columns: repeat(10, 1fr);
    ${column([3, 10])};
  }
`
export const Illustration = styled(IllustrationComponent)`
  display: none;
  width: 80%;
  max-width: ${g(40)};
  align-self: center;
  justify-self: center;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    ${column([1, 4])};
    display: block;
  }
`
export const ArticleBlock = styled.div`
  ${column([1, 4])};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    ${column([5, 5])};
  }

  @media only screen and (min-width: ${breakpoints.laptop}) {
    ${column([6, 5])};
  }
`
export const Title = styled.div`
  ${typo.heading32}
  margin-bottom: ${g(4)};
`
export const Article = styled.a`
  display: flex;

  & + & {
    margin-top: ${g(3)};
  }
`
export const Picture = styled(Image).attrs({ options: { crop: 'fill', width: ng(15), height: ng(9) } })`
  margin-right: ${g(3)};
  flex-shrink: 0;
`

export const ArticleTitle = styled.div`
  ${typo.heading16}
  margin-bottom: ${g(1)};
`
export const Details = styled.div`
  ${typo.caption12}
  display: flex;
`
